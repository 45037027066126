import React, {Component} from 'react'
import {Input} from "antd";

import PropTypes from "prop-types"
import styles from "./index.module.scss"


const rangeInputContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
}

const inputWrapperStyle = {
  position: "relative"
}

const inputTipStyle = {
  fontSize:"12px",
  position: "absolute",
  left: "5px",
  bottom: "-16px",
  lineHeight: "16px",
  width: 200,
  color: "#EC2200",
  zIndex:10
}

/**
 * @props name:String
 * @props prefix:String
 * @props suffix:String
 * @props size:String
 * @props style:Object
 * @props value:Array [start,end]
 * @props onChange([start,end],boolean):Function
 */
class RangeInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rangeStart: '',
      rangeStartValidate: {
        validateStatus: true,
        tip: ''
      },
      rangeEnd: '',
      rangeEndValidate: {
        validateStatus: true,
        tip: ''
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.value!==nextProps.value){
      this.setState({
        rangeStart:nextProps.value[0],
        rangeEnd:nextProps.value[1]
      })
    }
  }


  rangeStartChange = e => {
    this.setState({
      rangeEndValidate: {
        validateStatus: true,
        tip: ''
      },
    })
    let value = e.target.value
    let validate = {
      validateStatus: true,
      tip: ''
    }
    if (value === '' || value == 0) {
      validate = {
        validateStatus: true,
        tip: ''
      }
    } else if (isNaN(value) || +value < 0) {
      value = ''
      validate = {
        validateStatus: true,
        tip: ''
      }
    } else if (this.state.rangeEnd && +this.state.rangeEnd > 0 && +value > 0 && +value > +this.state.rangeEnd) {
      validate = {
        validateStatus: false,
        tip: '前者不能大于后者'
      }
    } else {
      validate = {
        validateStatus: true,
        tip: ''
      }
    }
    this.setState({
      rangeStart: value,
      rangeStartValidate: validate
    }, () => {
      const {rangeStart,rangeEnd,rangeStartValidate,rangeEndValidate}=this.state
      this.props.onChange([rangeStart, rangeEnd],
        rangeStartValidate.validateStatus && rangeEndValidate.validateStatus)
    })
  }

  rangeEndChange = e => {
    this.setState({
      rangeStartValidate: {
        validateStatus: true,
        tip: ''
      },
    })
    let value = e.target.value
    let validate = {
      validateStatus: true,
      tip: ''
    }
    if (value === '' || value == 0) {
      validate = {
        validateStatus: true,
        tip: ''
      }
    } else if (isNaN(value) || +value < 0) {
      value = ''
      validate = {
        validateStatus: true,
        tip: ''
      }
    } else if (this.state.rangeStart && +this.state.rangeStart > 0 && +value > 0 && +value < +this.state.rangeStart) {
      validate = {
        validateStatus: false,
        tip: '后者不能小于前者'
      }
    } else {
      validate = {
        validateStatus: true,
        tip: ''
      }
    }
    this.setState({
      rangeEnd: value,
      rangeEndValidate: validate
    }, () => {
      this.props.onChange([this.state.rangeStart, this.state.rangeEnd], this.state.rangeStartValidate.validateStatus && this.state.rangeEndValidate.validateStatus)
    })
  }


  render() {
    const {name, suffix, prefix,size,style,divider} = this.props

    return (
      <div className={styles.range_container} style={rangeInputContainerStyle}>
        <div  style={inputWrapperStyle}>
          <Input suffix={suffix}
                 prefix={prefix}
                 allowClear={true}
                 style={style}
                 size={size}
                 placeholder={`${name}起始`}
                 value={this.state.rangeStart}
                 onChange={this.rangeStartChange}/>
          {this.state.rangeStartValidate.validateStatus ? "" :
            <p style={inputTipStyle}>{this.state.rangeStartValidate.tip}</p>}
        </div>

        <span style={{margin: "0 10px"}}>{divider||"-"}</span>
        <div style={inputWrapperStyle}>
          <Input suffix={suffix} prefix={prefix} allowClear={true}
                 style={style}
                 size={size}
                 placeholder={`${name}结束`}
                 value={this.state.rangeEnd}
                 onChange={this.rangeEndChange}/>
          {this.state.rangeEndValidate.validateStatus ? "" :
            <p style={inputTipStyle}>{this.state.rangeEndValidate.tip}</p>}
        </div>
      </div>

    )
  }
}


/**
 * 校验传入props
 */

// RangeInput.propTypes={
//   name:PropTypes.string,
//   prefix:Input.propTypes.prefix,
//   suffix:Input.propTypes.suffix,
//   size:Input.propTypes.size,
//   divider:PropTypes.string,
//   style:PropTypes.object,
//   value:PropTypes.arrayOf(Input.propTypes.value),
//   onChange:PropTypes.func.isRequired,
// }

/**
 * 传入props默认值
 * @type {{name: string}}
 */
RangeInput.defaultProps={
  name:"数值"
}
export default RangeInput
