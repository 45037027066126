export const routers = [
  {
    id: 1,
    key: "customer",
    name: "客户管理",
    children: [{
      id: 11,
      name: "客户列表",
      key: "customer-list",
      route: "/customer/customer-list",
    }]
  },
  {
    id: 2,
    key: "official-website",
    name: "百礼腾官网",
    children: [
    //   {
    //   id: 21,
    //   name: "官网设置",
    //   key: "setting",
    //   route: "/official-website/setting",
    // },
    {
      id: 22,
      name: "客户线索",
      key: "customer-clue",
      route: "/official-website/customer-clue",
    }]
  },
  {
    id: 3,
    key: "applet",
    name: "小程序",
    children: [{
      id: 31,
      name: "模板管理",
      key: "template-manage",
      route: "/applet/template-manage",
    }, {
      id: 32,
      name: "客户小程序",
      key: "customer-applet",
      route: "/applet/customer-applet",
    }]
  },
  {
    id: 4,
    key: "market-operate",
    name: "商城运营",
    children: [{
      id: 41,
      name: "商城素材",
      key: "material",
      route: "/market-operate/material",
    }, {
      id: 42,
      name: "商城模板",
      key: "template",
      route: "/market-operate/template",
    }, {
      id: 43,
      name: '默认商品',
      key: 'goods',
      route: '/market-operate/goods',
    }]
  },
];