import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"

const {common} = api

class CommonStore {
  /* 用户信息 */
  @observable userInfo = {};

  /**
   * 获取用户的状态信息
   */
  @action
  postUserInfo = async (params) => {
    const data = await httpClient.post(common.POST_USER_INFO, {...params});
    if (data.success) {
      this.userInfo = data.data;
    }
  }

  /**
   * 上传文件
   */
  @action
  postBatchUploadFile = async (params) => {
    const data = await httpClient.post(common.POST_BATCH_UPLOAD_FILE_RETURN_IDS, params);
    return data;
  }
}

export default new CommonStore();
