import { action } from 'mobx';
import httpClient from 'utils/httpClient';
import api from 'config/api';

const { official } = api;


class OfficialStore {
  /**
   * 获取官网pv统计
   * @param {*} params 
   */
  @action
  async getOfficialPvStat(params) {
    const res = await httpClient.get(official.GET_OFFICIAL_WEBSITE_STAT, { params });
    return res;
  }

   /**
   * 获取客户线索列表
   * @param {*} params 
   */
  @action
  async getCustomerClueList(params) {
    const res = await httpClient.get(official.GET_CUSTOMER_CLUE_LIST, { params });
    return res;
  }

}

export default new OfficialStore();
