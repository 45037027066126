import React, {Component} from "react";
import {Button, Input} from 'antd'
import {inject, observer} from "mobx-react";


import styles from "./index.module.scss"

@inject("commonStore")
@observer
class Test extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showFreightAreaModal: false,
      account: '',
      password: "",
      pickJdGoods: true
    }
  }


  login = () => {

  }

  render() {
    return (
      <div className={styles.normal_page_container}>
        <div className={styles.normal_page_full} style={{backgroundColor: '#999', height: 200}}>
          <Button type="primary"  className={styles.theme_btn}>123123123</Button>
        </div>
        <div className={styles.normal_page_content} style={{backgroundColor: '#eee', height: 200}}>
          <Button type="primary" disabled className={styles.theme_btn}>123123123</Button>
        </div>


      </div>
    )
  }
}

export default Test;
