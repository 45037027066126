import theme from "./theme"

const ENV = process.env.REACT_APP_API_ENV;
const apiHost = {
  dev: "http://dev-gateway.fxqifu.net/pangu",
  // test:"https://qa-gateway.fxqifu.net/pangu",
  test: "https://qa-gateway.peliten.net/pangu",
  prod: "https://gateway.fxqifu.net/pangu"
};

const mainHost = apiHost[ENV];
const DATA_CODE = {
  SUCCESS: 2000,
  QUERY_PARAMS_ERROR: 4000,
  LOGIN_ERROR: "100003",
  OTHER_ERROR: 5000
};

export const config = {
  mainHost,
  theme,
  DATA_CODE
};
