import { action } from 'mobx';
import httpClient from 'utils/httpClient';
import api from 'config/api';

const { market } = api;


class MarketStore {
  /**
   * 轮播图列表
   * @param {*} params 
   */
  @action
  async getCarouselList(params) {
    const res = await httpClient.post(market.POST_GET_CAROUSEL_LIST, { ...params });
    return res;
  }

  /**
   * 添加轮播图
   * @param {*} params 
   */
  @action
  async addCarouselPic(params) {
    const res = await httpClient.post(market.POST_ADD_CAROUSEL, { ...params });
    return res;
  }

  /**
   * 删除轮播图
   * @param {*} params 
   */
  @action
  async delCarouselPic(params) {
    const res = await httpClient.post(market.POST_DELETE_CAROUSEL, { ...params });
    return res;
  }

   /**
   * 广告图列表
   * @param {*} params 
   */
  @action
  async getBannerList(params) {
    const res = await httpClient.post(market.POST_GET_BANNER_LIST, { ...params });
    return res;
  }

  /**
   * 添加广告图
   * @param {*} params 
   */
  @action
  async addBannerPic(params) {
    const res = await httpClient.post(market.POST_ADD_BANNER, { ...params });
    return res;
  }

  /**
   * 删除广告图
   * @param {*} params 
   */
  @action
  async delBannerPic(params) {
    const res = await httpClient.post(market.POST_DELETE_BANNER, { ...params });
    return res;
  }

  /**
   * 导航图列表
   * @param {*} params 
   */
  @action
  async getNavList(params) {
    const res = await httpClient.post(market.POST_GET_NAV_LIST, { ...params });
    return res;
  }

  /**
   * 添加导航图
   * @param {*} params 
   */
  @action
  async addNavPic(params) {
    const res = await httpClient.post(market.POST_ADD_NAV, { ...params });
    return res;
  }

  /**
   * 删除导航图
   * @param {*} params 
   */
  @action
  async delNavPic(params) {
    const res = await httpClient.post(market.POST_DELETE_NAV, { ...params });
    return res;
  }
}

export default new MarketStore();
