import { action, observable } from 'mobx';
import httpClient from 'utils/httpClient';
import api from 'config/api';

const { customer } = api;


class CustomerStore {

  /**
   * 获取卖家统计数据
   * @param {*} params 
   */
  @action
  async getCustomerStat(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_STATISTIC, { params });
    return res;
  }

  /**
   * 获取卖家列表
   * @param {*} params 
   */
  @action
  async getCustomerList(params) {
    const res = await httpClient.post(customer.POST_GET_CUSTOMER_LIST, { ...params });
    return res;
  }

  /**
   * 获取卖家购买记录
   * @param {*} params 
   */
  @action
  async getCustomerBuyRecords(params) {
    const res = await httpClient.get(customer.GET_CUSTOMER_BUY_RECORDS, { params });
    return res;
  }
}

export default new CustomerStore();
