import Loading from './Loading'
import Bread from './Bread'
import Footer from "./Footer"
import TopBar from './TopBar'

import CheckTags from './CheckTags';
import ScrollImgs from './ScrollImgs';
import ModuleTitle from './ModuleTitle'
import RangeInput from './RangeInput'
// import PageMenu from './PageMenu'
import Order from "./Order"
//ListItem

//Modal


const ListItem = {},
  Modals = {};

export {
  Loading,
  Bread, Footer, TopBar,
  CheckTags, ScrollImgs, ModuleTitle, RangeInput, Order,
  ListItem, Modals
}
