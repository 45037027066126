import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {mainHost} from "../../config";
import MD5 from 'md5';
import styles from "./index.module.scss";


@inject("commonStore")
@observer

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (<div className={styles.container}>
        登陆
      </div>
    );
  }
}

export default Login;
