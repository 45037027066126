import React, {Component} from "react";
import {Icon, Button, Divider, Menu,message} from "antd";
import {inject, observer} from "mobx-react";
import qs from 'qs'
import styles from "./index.module.scss";
import {routers} from "config/route";

const {Item} = Menu

@inject("commonStore")
@observer
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeeModal: false,
      barType: "c",
      menuCurrent: "home"
    }
  }

  componentDidMount() {
    const {postUserInfo} = this.props.commonStore;
    const pathname=window.location.pathname,params=qs.parse(window.location.search.slice(1))
    if(pathname==="/home"){
      if(params.token){
        window.localStorage.setItem("token",params.token);
        postUserInfo({})
      }else{
        message.error("请确认页面来自何方~")
      }
    }
  }

  logout = () => {
    this.props.commonStore.logout()
  };

  render() {
    const {showFeeModal, barType, menuCurrent} = this.state;
    const showBarTypeMap = {a: 1, b: 2, c: 3};

    return (
      <div className={`${styles.top_banner} ${showBarTypeMap[barType] === 3 && styles.top_banner_dark}`}>
        <div className={styles.topbar_container}>
          <div className={styles.topbar_left}>
            <span className={styles.topbar_title}>百礼腾运营后台</span>
          </div>
          

          <div className={styles.topbar_right}>
            {/* <span style={{margin: 0}}>张三丰</span> */}
            {/* <Divider type="vertical"
                     style={{
                       backgroundColor: showBarTypeMap[barType] === 3 ? "#fff" : "#ccc",
                       height: "20px"
                     }}/> */}
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                localStorage.removeItem('token');
                const hostMap = {
                  dev: 'http://dev-admin.fxqifu.net/home',
                  test: 'http://qa-admin.fxqifu.net/home',
                  prod: 'https://admin.fxqifu.net/home',
                }
                const env = process.env.REACT_APP_API_ENV || 'dev';
                window.location.href = hostMap[env];
              }}
            >
              退出
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
