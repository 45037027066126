const api = {
  // 通用接口
  common: {
    POST_LOGOUT: "/right/login/logout", //退出
    POST_UPLOAD_FILE: "/basic/file/upload", // 上传文件
    POST_UPLOAD_FILE_RETURN_ID: "/basic/file/uploadNew", // 上传文件返回id
    POST_BATCH_UPLOAD_FILE_RETURN_IDS: "/basic/file/batchUploadNew", // 批量上传文件返回id数组
  },
  // 客户管理
  customer: {
    GET_CUSTOMER_BUY_RECORDS: "/member/product/my-buy-records", // 获取卖家购买记录
    GET_CUSTOMER_STATISTIC: "/admin/member/product/sellers-statistics/query", // 获取卖家统计数据
    POST_GET_CUSTOMER_LIST: "/admin/member/product/sellers", // 获取客户列表
  },
  // 官网
  official: {
    GET_OFFICIAL_WEBSITE_STAT: "/gift/official-website/official-website-stat/get", // 获取官网pv统计
    GET_CUSTOMER_CLUE_LIST: "/gift/official-website/customer-lead/listByPage", // 客户线索列表
  },
  // 小程序
  applet: {
    GET_TEMPLATE_LIST_FROM_WECHAT: "/gift/wechat/miniapp/syncTemplateList", // 从微信服务器同步小程序模板配置信息
    GET_APPLET_TEMPLATE_LIST: "/gift/wechat/miniapp/getTemplateList", // 获取小程序模板
    GET_TEMPLATE_CONFIG: "/gift/wechat/miniapp/getTemplateById", // 获取模板配置
    POST_SET_DEFAULT_TEMPLATE_VERSION: "/gift/wechat/miniapp/releaseTemplate", // 将小程序模板设置为默认版本
    POST_GET_CUSTOMER_LIST: "/gift/wechat/miniapp/queryPage", // 应用版本-客户列表
    POST_GET_RELEASE_AND_AUDIT_CUSTOMER_LIST: "/gift/wechat/miniapp/getAppReleaseAndAudit", // 应用审核-客户列表
    POST_APP_BATCH_UPDATE: "/gift/wechat/miniapp/appBatchUpdate", // 小程序批量升级
    POST_UPDATE_TEMPLATE_CONFIG: "/gift/wechat/miniapp/updateTemplate", // 更新模板配置
    POST_GET_VERSION_LIST: "/gift/wechat/miniapp/querySerialPage", // 小程序审核版本列表
  },
  // 商城运营
  market: {
    POST_GET_CAROUSEL_LIST: "/gift/admin/mall-material/carousel-page-list", // 轮播图列表
    POST_ADD_CAROUSEL: "/gift/admin/mall-material/carousel-add", // 添加轮播图
    POST_DELETE_CAROUSEL: "/gift/admin/mall-material/carousel-delete", // 删除轮播图
    POST_GET_BANNER_LIST: "/gift/admin/mall-material/ad-page-list", // 广告图列表
    POST_ADD_BANNER: "/gift/admin/mall-material/ad-add", // 添加广告图
    POST_DELETE_BANNER: "/gift/admin/mall-material/ad-delete", // 删除广告图
    POST_GET_NAV_LIST: "/gift/admin/mall-material/navigator-page-list", // 导航图列表
    POST_ADD_NAV: "/gift/admin/mall-material/navigator-add", // 添加导航图
    POST_DELETE_NAV: "/gift/admin/mall-material/navigator-delete", // 删除导航图
  },
}

export default api;