import localStore from "./local"
import commonStore from "./common";
import customerStore from './customer';
import marketStore from './market';
import OfficialStore from './official';

export default {
  localStore,
  commonStore,
  customerStore,
  marketStore,
  OfficialStore,
}
